.banner{
  background-image: url('./images/banner.png');
}
.foofter_bg{  
  background-image: url('./images/footer_bg.png');
}
.h-400{
  min-height: calc(400px + 20vh);
}
.book-btn{
  background: #f3e454;
    padding: 7px 29px;
    font-size: 14px;
    border-radius: 30px;
    color: #000;
}
.contact-bg{
  background: #f1eeeb96;
}
.blink {
  background-color: #f3e454;
  -webkit-animation: blink 800ms step-end infinite;
          animation: blink 800ms step-end infinite;
}
.sub_bg{
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  border-bottom: 4px solid #e32d03;
  margin-bottom: 40px;
}
.sub_bg::after{
  position: absolute;
  content: '';
  background: #0000008f;
  z-index: 0;
  width: 100%;
  height: 100%;
  top: 0;
}
.contact-btn{
  background: #FFAD01;
    margin-left: 10px;
}
.footer_brd{
  background: url(./images/footer-city-bg-transparent.png);
  background-repeat: no-repeat;
  padding: 55px 0px;
  width: 100%;
}
.bath_date_box{
  display: inline-block;
  width: 100%;
}

.bath_date_box_left{
  width: 100%;
  display: block;
}
.bath_date_box_left img{
  width: 100%;
  border-radius: 20px;
    overflow: hidden;
}
.bath_date_box_right{
display: inline;
}
.bathing-dates {
  background: url(./images/bathing.webp), lightgray 50% / cover no-repeat;
    background-size: cover;
    display: flex;
    justify-content: space-around;
    position: relative;
}
.bathing-dates .bath-data {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 56px;
  position: relative;
  z-index: 9;
}
.bathing-dates .bath-data .date-tiles {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  flex-wrap: wrap;
}
.bathing-dates .bath-data .date-tiles .tile {
  background-color: white;
    border-radius: 8px;
    text-align: center;
    padding: 34px 13px;
    color: var(--Prim-Gray, #333);
    border: 1px solid #ED5722;
    transition: 0.3s ease all;
    width: 31%;
}
.bathing-dates .bath-data .date-tiles .tile .day-name {
  text-align: center;
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  letter-spacing: 1.92px;
  text-transform: uppercase;
}
.bathing-dates .bath-data .date-tiles .tile .day-date {
  text-align: center;
  font-family: Poppins;
  font-size: 64px;
  font-style: normal;
  font-weight: 900;
  line-height: 120%;
  color: #e32d03;
  text-transform: uppercase;
}
.bathing-dates .bath-data .date-tiles .tile .day-month, .bathing-dates .bath-data .date-tiles .tile .day-year {
  text-align: center;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  text-transform: uppercase;
}
.bathing-dates .bath-data .date-tiles .tile .day-month, .bathing-dates .bath-data .date-tiles .tile .day-year {
  text-align: center;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  text-transform: uppercase;
}
.subheading{
  font-size: 16px;
  font-weight: bold;
}
.book_popup{
  top: 0;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 1111;
  background: #000000ab;
  padding: 20px;
  overflow-y: auto;
}
.popheading{
  font-weight: 600;
  font-size: 19px;
  display: block;
  text-align: left;
  margin-bottom: 19px;
  font-family: "Alice", serif;

}
.book_popup_inner{
  position: relative;
  padding: 20px;
  max-width: 1040px;
  background: #fff;
  display: inline-flex;
  gap: 30px;
  box-shadow: 0px 2px 52px #ffffffb5;
}
.book_popup_inner_left{
  display: inline-block;
  width: 40%;
  text-align: center;
}
.book_popup_inner_left img{
  width: 100%;
}
.book_popup_inner_right{
  display: inline-block;
  width: 60%;
}
.book_popup_inner_close{
  position: absolute;
  top: -30px;
  right: -30px;
  font-size: 17px;
  cursor: pointer;
  background: #fff;
  border: 0;
  color: #f00;
  border-radius: 100%;
  width: 30px;
  height: 30px;

}
.error-text{
  color: #f00;
  position: absolute;
  bottom: -13px;
  font-size: 13px;
}
.error-text-form{
  color: #f00;
  font-size: 0.875em;
  font-weight: bold;
  margin-top: 5px;
}
#mobile-menu-wrap{
  display: none;
}

.success-text {
  color: green;
  font-weight: bold;
}
.term-title{
display: block;
width: 100%;
}
.term-title h3{
  font-size: 24px;
  font-weight: bold;
}
.term-title p{
  font-size: 14px;
  font-weight: 500;
}
.term-title ul{
display: block;
padding-left: 20px;
margin:15px 0px ;
}
.term-title ul li{
font-size: 14px;
font-family: "Work Sans", sans-serif;
color: #6a6b7c;
font-weight: 500;
}
.term-title p.red-note{
color: #f00;
margin-top: 20px;
}
.book_popup_inner2 {
  position: relative;
  padding: 20px;
  max-width: 610px;
  background: #fff;
  display: inline-flex;
  gap: 30px;
  box-shadow: 0px 2px 52px #ffffffb5;
}
.book_popup_inner_right2 {
  display: inline-block;
  width: 100%;
}

.book_popup_inner_right2  .cs-text {
  margin-top: 20px;
}
.book_popup_inner_right2 .cs-text ul li{
  font-weight: bold;
}
@media (max-width: 680px){
  .nav-menu{
    display: none;
  }
  #mobile-menu-wrap {
    display: inline-block;
    position: absolute;
    right: 20px;
    font-size: 25px;
    top: 27px;
}
.logo img {
  max-height: 62px;
}
.activemenu, .activemenu .mainmenu{
  display: block !important;
  width: 100%;
  background: #eeeeee8f;
  padding: 0px 8px;
}
.nav-menu .mainmenu ul li { 
  margin-right: 0; 
  width: 100%;
}
.nav-menu .mainmenu ul li a {    
  padding: 10px 0;  
  width: 100%;
}
.nav-menu .primary-btn.top-btn {
  display: block !important;
  margin-top: 13px;
}
.contact-btn{
  display: block;
  margin-left: 0px;
  margin-bottom: 10px;
}
.section-title p span.txtbg {
   line-height: 35px;
}
.spad {
  padding-top: 50px;
  padding-bottom: 30px;
}
.bathing-dates .bath-data .date-tiles .tile {
   width: 99%;
}
.bath_date_box_left {
   margin-bottom: 18px;
}
.book-btn {
   display: block;
  margin-top: 20px; 
  padding: 12px 29px;
}
.counter-section .counter-text {
  margin-bottom: 12px;
}
.nav-menu .mainmenu ul li a:after {
   bottom: 0;
}
.breadcrumb-text h2 {
  font-size: 26px;
    padding: 10px;
}
.bd-text .bd-more-text ul{
  padding-left: 15px;
}
.blog-details-section {
  padding-top: 30px;
}
.hero-section .hero-text {
  padding-top: 24px;
}
.h-400 {
  min-height: calc(230px + 20vh);
}
.hero-section .hero-text h4 {
   font-size: 20px;
}
.banner {
   background-position: left;
}
.ha-text h2 {
   font-size: 28px;
}
.section-title h2 {
  font-size: 30px;
}
.bathing-dates .bath-data .date-tiles .tile .day-date {
   font-size: 54px;
}
.sub_bg{
  margin-bottom: 10px;
}
.about-text h3 {
   font-size: 30px;
}
.book_popup{
  padding: 20px;
}
.book_popup_inner{
  flex-wrap: wrap;
}
.book_popup_inner_left {
  display: inline-block;
  width: 100%;
}
.book_popup_inner_right {
  display: none;
}
.book_popup_inner_close {
  position: absolute;
  top: -10px;
  right: -9px;
}
}

@-webkit-keyframes blink { 50% { background-color: red; }}
      @keyframes blink { 50% { background-color: red; }}
 